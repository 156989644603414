import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import React from "react";

import { classNames } from "../utils";
import { useState } from "react";

type ModalProps = {
    mood?: ModalMood;
    open: boolean;
    onClose: () => void;
    pCancelButtonRef: React.RefObject<HTMLButtonElement>;
};

export type ModalMood = "default" | "affirmative" | "negation" | "neutral";

export default function VoucherModal({ open, onClose, pCancelButtonRef, mood = "default" }: ModalProps) {
    const [greeting, setGreeting] = useState<string>("");
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" auto-reopen="true" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
                <div className="absolute bg-white shadow-lg h-96 ">test</div>
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={classNames(
                                "inline-block align-bottom  mx-auto  px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
                            )}
                        >
                            <div className="flex flex-row mx-auto ">
                                <div className="px-8 py-8 -my-8 text-sm bg-white w-72 h-102 " id="preview">
                                    <p style={{ fontSize: "50%" }} className="mt-8 text-sm text-center">
                                        Gutschein für2
                                    </p>
                                    <p style={{ fontSize: "70%" }} className="mt-1 font-bold text-center">
                                        Brunch Box Andrew
                                    </p>
                                    <p style={{ fontSize: "50%" }} className="mt-0 mb-4 text-sm font-bold text-center">
                                        1 &times; Veuve Clicquot Champagner 37.5cl
                                    </p>{" "}
                                    <p style={{ fontSize: "50%" }} className="text-xs font-light text-center">
                                        Gültig bis: 24.10.2021
                                    </p>
                                    <p style={{ fontSize: "30%" }} className="mt-3 text-xs font-light text-center">
                                        Gutscheincode{" "}
                                    </p>
                                    <p style={{ fontSize: "60%" }} className="text-xs font-light text-center">
                                        BUTLER1234XYZ
                                    </p>
                                    <p style={{ fontSize: "80%" }} className="mt-6 text-sm font-normal text-center">
                                        {greeting}
                                    </p>
                                </div>
                                <div className={"  bg-black py-12  px-8 font-normal"}>
                                    <label className="text-sm leading-loose text-white label">
                                        Grussnachricht
                                        <textarea
                                            className="w-full h-32 text-gray-700 textarea"
                                            placeholder="Grussnachricht"
                                            onKeyUp={(e: React.KeyboardEvent<HTMLTextAreaElement>) => setGreeting(e.currentTarget.value)}
                                            name="test"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
