import React, { useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useCart from "../context/cart.context";
import { classNames } from "../utils";
import { useEffect } from "react";
import { Coupon } from "../queries";
import { useMutation } from "@apollo/client";
import { ADD_BUNDLE_TO_CART, VALIDATE_VOUCHER } from "../mutations";
import { navigate } from "gatsby";
import { useVoucherData } from "../context/voucher-data.context";

type ModalProps = {
    mood?: ModalMood;
    open: boolean;
    onClose: () => void;
    pCancelButtonRef: React.RefObject<HTMLButtonElement>;
    defaultCode?: string;
};

export type ModalMood = "default" | "affirmative" | "negation" | "neutral" | "overflowed" | "expired" | "already_applied";

const moodBgColors: Record<ModalMood, string> = {
    default: "bg-black",
    affirmative: "bg-affirmative",
    negation: "bg-cannonPink",
    neutral: "bg-neutral",
    overflowed: "bg-cannonPink",
    expired: "bg-cannonPink",
    already_applied: "bg-cannonPink"
};

const moodTextColors: Record<ModalMood, string> = {
    default: "text-white",
    affirmative: "text-black",
    negation: "text-white",
    neutral: "text-black",
    overflowed: "text-white",
    expired: "text-white",
    already_applied: "text-white"
};

export default function Modal({ open, onClose, pCancelButtonRef, defaultCode }: ModalProps) {
    const cancelButtonRef = useRef(pCancelButtonRef);
    const { voucherCode, setVoucherCode, refetch } = useCart();
    const [addBundleToCart] = useMutation(ADD_BUNDLE_TO_CART);
    const [checkVoucherCode] = useMutation(VALIDATE_VOUCHER);
    const [code, setCode] = useState<string>(defaultCode || "");
    const [checking, setIsChecking] = useState<boolean>(false);
    const [checkResult, setCheckResult] = useState<ModalMood | null>(null);
    const [slug, setSlug] = useState<string>("");
    const {changeVoucherData, voucherDataIsExist} = useVoucherData()

    const { applyVoucherCode, cart, setLastVoucherCode, emptyCart } = useCart();

    const DialogTitle = () => {
        switch (checkResult) {
            case "affirmative":
                return "Alles klar!";
            case "negation":
                return "Ich konnte keinen Gutschein mit diesem Code finden.";
            case "overflowed":
                return "Das Limit für die Verwendung dieses Gutscheins wurde erreicht. Bitte versuchen Sie es nach einiger Zeit erneut, oder kontaktieren Sie uns"
            case "expired":
                return "Ihr Gutschein ist abgelaufen";
            case "already_applied":
                return "Gutschein bereits angewendet";
            default:
                return "Gutschein einlösen";
        }
    };
    const DialogDescription = () => {
        switch (checkResult) {
            case "affirmative":
                return "Der Gutscheincode ist gültig. Wir haben die Produkte dem Warenkorb hinzugefügt.";
            case "negation":
                return "Bitte überprüfen Sie Ihre Eingabe.";
            case "overflowed":
                return
            case "expired":
                return "Um den Gutschein zu verlängern, senden Sie mir bitte eine E-Mail mit dem Gutscheincode an: butler@brunchbutler.ch"
            case "already_applied":
                return;
            default:
                return "Lösen Sie hier ihren Gutschein ein.";
        }
    };

    useEffect(() => {
        setCheckResult(null);
    }, [open]);

    const onCheck = async () => {
        const result = await checkVoucherCode({ variables: { code: code } }).catch(() => undefined);
        if(result && result?.data && result?.data?.validateVoucher && result.data?.validateVoucher?.valid && !cart?.appliedCoupons?.length) {
            setCheckResult("affirmative");
            setVoucherCode(code);
            if(result.data.validateVoucher.slug){
                setSlug(result.data.validateVoucher.slug);
            }
            if (result.data.validateVoucher.shipping_data) {
                changeVoucherData({ shipping: JSON.parse(result.data.validateVoucher.shipping_data), supplements: result.data.validateVoucher.supplements });
            }
            console.log(result.data.validateVoucher);
            setLastVoucherCode(code);
            code.startsWith("OLD") && cart.products?.length &&  await emptyCart();
            await refetch()
        } else {
            setCheckResult("negation");
        }
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                auto-reopen="true"
                className="fixed inset-0 z-10 overflow-y-auto"
                initialFocus={cancelButtonRef.current}
                onClose={onClose}
            >
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={classNames(
                                moodBgColors[checkResult ?? "default"],
                                moodTextColors[checkResult ?? "default"],
                                "inline-block align-bottom px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:px-20 sm:py-8"
                            )}
                        >
                            <form
                                onKeyPress={(e: React.KeyboardEvent<HTMLFormElement>) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onCheck();
                                    }
                                }}
                                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onCheck();
                                }}
                            >
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title
                                        as="h3"
                                        className={classNames("text-4xl font-medium font-serif", moodTextColors[checkResult ?? "default"])}
                                    >
                                        {DialogTitle()}
                                    </Dialog.Title>

                                    <p className={classNames("text-lg mb-6 mt-6", moodTextColors[checkResult ?? "default"])}>{DialogDescription()}</p>
                                    {checkResult !== "affirmative" && (
                                        <input
                                            type="text"
                                            disabled={checking}
                                            value={code}
                                            placeholder={"Gutscheincode"}
                                            onFocus={() => setCheckResult(null)}
                                            className={classNames(
                                                "text-gray-700 text-center text-md w-full border-0 outline-none focus:outline-none ",
                                                checking ? "cursor-not-allowed disabled" : ""
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.currentTarget.value)}
                                        />
                                    )}
                                </div>
                                <div className={classNames("mt-1 flex space-x-3 items-end justify-end")}>
                                    {checkResult !== "affirmative" && (
                                        <button
                                            type="button"
                                            className="px-8 py-2 mt-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-standardGray hover:bg-gray-500 active:bg-gray-700 sm:mt-2 focus:outline-none sm:col-start-0"
                                            onClick={onClose}
                                            ref={cancelButtonRef.current}
                                        >
                                            Später
                                        </button>
                                    )}
                                    {checkResult !== "affirmative" && (
                                        <button
                                            type="submit"
                                            autoFocus={true}
                                            className="flex items-center justify-center px-5 py-2 mt-2 space-x-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-olive hover:bg-amber-500 focus:border-amber-700 active:bg-amber-700 focus:outline-none sm:col-start-2"
                                        >
                                            {checking && <SpinnerIcon />}
                                            {checking ? "Bitte warten" : "Überprüfen"}
                                        </button>
                                    )}
                                    {checkResult === "affirmative" && (
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-5 py-2 text-base font-medium leading-6 text-black transition duration-150 ease-in-out bg-white border border-transparent shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:text-sm hover:bg-amber-500 focus:border-amber-700 active:bg-amber-700 "
                                            onClick={() => {
                                                onClose();
                                                slug && navigate('/lieferung/' + slug);
                                            }}
                                            ref={cancelButtonRef.current}
                                        >
                                            Weiter
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

const SpinnerIcon = () => {
    return (
        <svg className="w-5 h-5 mr-3 -ml-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
        </svg>
    );
};
