import "csshake/dist/csshake.min.css";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
import FacebookIcon from "../images/facebook-icon.svg";
import InstagramIcon from "../images/instagram-icon.svg";
import LinkedinIcon from "../images/linkedin.svg";
import LogoWide from "../images/logo-wide.svg";
import MasterCardLogo from "../images/BrunchButler-card_mastercard.svg";
import TwintLogo from "../images/BrunchButler-card_twint.svg";
import VisaLogo from "../images/BrunchButler-card_visa.svg";
import AppleLogo from "../images/BrunchButler-card_apple_pay.svg";
import GooglePayLogo from "../images/BrunchButler-card_google_pay.svg";
import PaypalLogo from "../images/BrunchButler-card_paypal.svg";
import PostFinanceCard from "../images/BrunchButler-card_postfinance_card.svg";
import PostFinanceEfinance from "../images/BrunchButler-card_postfinance_efinance.svg"
import InputBlock from "./InputBlock";

export default function Footer() {
    const {
        allWpMenu: { nodes },
    } = useStaticQuery(graphql`
        {
            allWpMenu(filter: { name: { eq: "Footer" } }) {
                nodes {
                    menuItems {
                        nodes {
                            id
                            label
                            url
                        }
                    }
                }
            }
        }
    `);
    return (
        <footer aria-labelledby="footer-heading" className="bg-darkGray">
            <h2 id="footer-heading" className="sr-only">
                Weiteres
            </h2>

            <div className="p-8 mx-auto max-w-7xl">
                <div className="logo">
                    <LogoWide onClick={() => navigate('/')} className="py-6 sm:h-[100px] sm:w-[330px] cursor-pointer" />
                </div>

                <div className="md:grid md:grid-cols-2 xl:grid-cols-4">
                    <div className="space-y-6 text-sm">
                        <b>BrunchButler – der Brunch-Kurier seit 2007</b>
                        <div className="flex flex-col">
                            <b>Anfragen und Feedbacks</b>
                            <a href="mailto:butler@brunchbutler.ch">butler@brunchbutler.ch</a>
                        </div>
                        <div className="flex flex-col">
                            <b>Presse</b>
                            <a href="mailto:presse@brunchbutler.ch" className="block">
                                presse@brunchbutler.ch
                            </a>
                        </div>
                        <div className="w-auto py-2">
                            <a href="https://www.facebook.com/BrunchButler.ch/" target="_blank" rel="noreferrer">
                                <FacebookIcon className="inline-block mr-2 hover:fill-current hover:text-black" height="30px" width="30px" />
                            </a>
                            <a href="https://www.instagram.com/brunchbutler/" target="_blank" rel="noreferrer">
                                <InstagramIcon className="mr-2 inline-block hover:fill-current hover:text-black" height="30px" width="30px" />
                            </a>
                            <a href="https://www.linkedin.com/company/brunchbutler/?viewAsMember=true" target="_blank" rel="noreferrer">
                                <LinkedinIcon className="inline-block hover:fill-current hover:text-black" height="30px" width="30px" />
                            </a>
                        </div>
                    </div>

                    <div className="pb-8 text-sm md:col-span-2 md:pl-72">
                        <h3 className="sr-only">Footer</h3>
                        {nodes.map((node: { menuItems: { nodes: { id: string; label: string; url: any }[] } }, index) => (
                            <ul className="pb-6" key={index}>
                                {node.menuItems.nodes.map((i) => (
                                    <li key={i.id}>
                                        {i.url == null && <h3 className="mt-6 font-medium">{i.label}</h3>}
                                        {i.url !== null && <a href={i.url}>{i.label}</a>}
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>

                    <div className="pt-6 text-sm md:col-start-1 md:col-end-6 xl:col-span-1">
                        {/* Newsletter section */}
                        <div>
                            <h3 className="font-medium">Bleiben sie auf dem Laufenden</h3>
                            <p className="mb-4 text-sm">
                                Melden Sie sich für unseren Newsletter an. So erfahren Sie alles zur Erweiterungen unseres Liefergebiets,
                                Vergünstigungen und Spezialangebote. Es lohnt sich!{" "}
                            </p>
                            <form
                                action="https://brunchbutler.us9.list-manage.com/subscribe/post?u=49a2f66c421fa2b75d395c674&amp;id=ab408107cf"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                target="_blank"
                                className="flex mt-2 sm:max-w-md form-newsletter"
                            >
                                <input
                                    className="space-y-0 foot customInput"
                                    type="text"
                                    required={true}
                                    placeholder="Ihre E-Mail Addresse"
                                    autoComplete="email"
                                />
                                <div className="flex-shrink-0 ml-4">
                                    <button
                                        type="submit"
                                        className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm bg-graniteGray hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        Anmelden
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <h3 className="mt-8 font-medium">Bezahlen Sie mit</h3>
                            <div className="grid grid-cols-3 grid-rows-3 gap-3.5 mt-6 justify-items-center">
                                <VisaLogo width="88px" height="auto" />
                                <MasterCardLogo width="88px" height="auto" />
                                <TwintLogo width="88px" height="auto" />
                                <GooglePayLogo width="88px" height="auto" />
                                <AppleLogo width="88px" height="auto" />
                                <PaypalLogo width="88px" height="auto" />
                                <PostFinanceCard width="88px" height="auto" />
                                <PostFinanceEfinance width="88px" height="auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-6 text-sm text-center bg-black">
                <p className="text-sm text-white">&copy; 2007-{new Date().getFullYear()} Copyright by BrunchButler. All rights reserved.</p>
            </div>
        </footer>
    );
}
