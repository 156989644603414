import React, { useEffect, useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useCart from "../context/cart.context";
import { classNames, getZipCode } from "../utils";
import { useMutation } from "@apollo/client";
import { UPDATE_SHIPPING_ADDRESS, UPDATE_SHIPPING_METHODS } from "../mutations";
import ZipcodeSearch from "@stefanzweifel/js-swiss-cantons/src/ZipcodeSearch";
import { useCheckZip } from "../hooks";
import { navigate } from "gatsby";

type ModalProps = {
  mood?: ModalMood;
  open: boolean;
  onClose: () => void;
  pCancelButtonRef: React.RefObject<HTMLButtonElement>;
  redirect?: string;
  selectedSlug?: string;
};

export type ModalMood =
  | "default"
  | "affirmative"
  | "negation"
  | "neutral"
  | "flatrate";
// | "cartnotempty";

const moodBgColors: Record<ModalMood, string> = {
  default: "bg-black",
  affirmative: "bg-romanCoffee",
  negation: "bg-cannonPink",
  neutral: "bg-romanCoffee",
  flatrate: "bg-romanCoffee",
  // cartnotempty: "bg-cannonPink",
};

const moodTextColors: Record<ModalMood, string> = {
  default: "text-white",
  affirmative: "text-white",
  negation: "text-white",
  neutral: "text-black",
  flatrate: "text-white",
  // cartnotempty: "text-white",
};

export default function Modal({
  open,
  onClose,
  pCancelButtonRef,
  redirect,
  mood = "default",
  selectedSlug = ""
}: ModalProps) {
  const [updateShippingAddress] = useMutation(UPDATE_SHIPPING_ADDRESS);
  const [updateShippingMethods] = useMutation(UPDATE_SHIPPING_METHODS);
  const [deliveryPrice, setDeliveryPrice] = useState<string>("");
  const { updateCart, zipCode,  setZipCode } = useCart();

  const cancelButtonRef = useRef(pCancelButtonRef);

  const [zip, setZip] = useState("");
  const [checking, setIsChecking] = useState<boolean>(false);
  const [checkResult, setCheckResult] = useState<ModalMood | null>(null);

  const checkZip = useCheckZip();

  const onCheck = async () => {
    const cantonChecker = new ZipcodeSearch();
    const locationData = cantonChecker.findbyZipcode(zip);
    if (locationData && locationData.canton) {
      const verifyZip = await checkZip(zip, locationData.canton);
      setIsChecking(true);
      if (verifyZip.delivery_price) {
        setCheckResult("flatrate");
        setDeliveryPrice(verifyZip.delivery_price);
        await updateCart({
          zipCode: zip,
        });
        await updateShippingAddress({
          variables: {
            postCode: zip,
          },
        }).then(() => {
          // updateShippingMethods({
          //   variables: { method: "flat_rate" },
          // }).catch((e) => {
          //   setCheckResult("negation");
          //   console.log(e);
          // });
          !zipCode && setZipCode(zip);
        });
      } else if (!verifyZip.delivery_price && verifyZip.working_days) {
        await updateCart({ zipCode: zip });
        await updateShippingAddress({
          variables: { postCode: zip },
        }).then(() => {
          setCheckResult("affirmative");
        });
      } else {
        setCheckResult("negation");
      }
    } else {
      setCheckResult("negation");
    }
    // if (verifyZip.variant === "flatrate") {
    //     setCheckResult(verifyZip.variant);
    //     await updateCart({ zipCode: zip });
    //     // await updateCart({ shippingTotal: 10 });
    //     await updateShippingAddress({
    //         variables: { postCode: zip },
    //     })
    //         .then(() =>
    //             updateShippingMethods({
    //                 variables: { method: "flat_rate" },
    //             })
    //         )
    //         .catch((error) => console.log(error));
    // } else if (verifyZip.variant === "affirmative") {
    //     setCheckResult(verifyZip.variant);
    //     await updateCart({ zipCode: zip });
    //     await updateShippingAddress({
    //         variables: { postCode: zip },
    //     })
    //         .then(() =>
    //             updateShippingMethods({
    //                 variables: { method: "free_shipping" },
    //             })
    //         )
    //         .catch((error) => console.log(error));
    // } else {
    //      setCheckResult(verifyZip.variant);
    // }

    setIsChecking(false);
  };

  const DialogTitleText = (checkResult: string) => {
    switch (checkResult) {
      case "affirmative":
        return "Alles ok!";
      case "negation":
        return "Ausserhalb";
      case "cartnotempty":
        return "Es tut mir leid!";
      case "flatrate":
        return "Lieferpauschale";
      default:
        return "Liefergebiet";
    }
  };

  useEffect(() => {
    zipCode && setZip(zipCode);
  }, []);

  // console.log(selectedSlug);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef.current}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center md:items-end sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                moodBgColors[checkResult ?? "default"],
                moodTextColors[checkResult ?? "default"],
                "text-center inline-block align-bottom  px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              )}
            >
              <div className="px-8 mt-3 md:px-14 sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className={classNames(
                    "text-4xl font-medium font-serif",
                    "text-" + moodTextColors[checkResult ?? "default"]
                  )}
                >
                  {DialogTitleText(checkResult)}
                </Dialog.Title>

                <div
                  className={classNames(
                    "mt-2 md:text-lg my-6 leading-6",
                    "text-" + moodTextColors[checkResult ?? "default"]
                  )}
                >
                  {checkResult === "affirmative" ? (
                    <div className="my-10">
                      <p>Die Ortschaft liegt in meinem Liefergebiet.</p>
                      <p className="mt-4">
                        <strong>
                          BrunchButler liefert nach
                          <br /> {zip}.
                        </strong>
                      </p>
                    </div>
                  ) : checkResult === "negation" ? (
                    <>
                      <p>
                        <strong>
                          Die Ortschaft liegt leider nicht im Liefergebiet von
                          BrunchButler.
                        </strong>
                        <br />
                        Meine Dienstleistung expandiert und erreicht vielleicht
                        bald auch Ihre Ortschaft.
                      </p>
                      <br />
                      <p>
                        <strong>Darf ich Sie auf dem Laufenden halten?</strong>
                      </p>
                    </>
                  ) : // ) : checkResult === "cartnotempty" ? (
                  //     <>
                  //         Die PLZ lässt sich nicht mehr ändern, nachdem ein Produkt dem Warenkorb hinzugefügt wurde.
                  //         <button
                  //             type="button"
                  //             className="block px-10 py-2 mt-5 ml-auto text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-olive hover:bg-amber-500 active:bg-amber-700 sm:mt-2 focus:outline-none sm:col-start-2"
                  //             onClick={onClose}
                  //             ref={cancelButtonRef.current}
                  //         >
                  //             Weiter
                  //         </button>
                  //     </>
                  checkResult === "flatrate" ? (
                    <>
                      Die Adresse liegt ausserhalb meines Liefergebiets.
                      <br />
                      Gegen eine zusatzliche Lieferpauschale liefere ich gerne
                      an Ihre Adresse
                      <br />
                      <strong>Zusatzliche Lieferpauschale CHF {deliveryPrice}</strong>
                      <div
                        className={classNames(
                          "pl-0 sm:pl-20 pb-5 mt-1 sm:gap-3 sm:grid-flow-row-dense",
                          " sm:grid sm:grid-cols-2"
                        )}
                      >
                        <button
                          type="button"
                          className="w-full py-2 mt-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-standardGray hover:bg-gray-500 active:bg-gray-700 sm:mt-2 focus:outline-none sm:col-start-0"
                          onClick={() => {
                            setCheckResult(null);
                            onClose();
                          }}
                          ref={cancelButtonRef.current}
                        >
                          Abbrechen
                        </button>
                        <button
                          type="button"
                          className="w-full py-2 mt-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-greenDeYork hover:bg-amber-500 active:bg-amber-700 sm:mt-2 focus:outline-none sm:col-start-2"
                          onClick={() => {
                            setCheckResult(null);
                          onClose();
                          if(selectedSlug) {
                            navigate(selectedSlug);
                          }
                          }}
                          ref={cancelButtonRef.current}
                        >
                          Weiter
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      BrunchButler liefert im Grossraum Zürich, Zug und St.
                      Moritz.
                      <br />
                      Hier können Sie Ihr gewünschtes Liefergebiet überprüfen,
                      ob es von mir bedient wird.
                      <br />
                      <strong>Bitte geben Sie eine Postleitzahl ein.</strong>
                    </>
                  )}
                </div>

                <div>
                  {checkResult === "negation" && (
                    <>
                      <form
                        action="https://brunchbutler.us9.list-manage.com/subscribe?u=49a2f66c421fa2b75d395c674&id=7bdf8c92a8"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="form-newsletter"
                        target="_blank"
                      >
                        <label htmlFor="email-address" className="sr-only">
                          E-Mail-Adresse
                        </label>
                        <input
                          id="email-address"
                          type="text"
                          name="EMAIL"
                          autoComplete="email"
                          placeholder={"Ihre E-mail-Addresse"}
                          className="w-full p-2 text-sm text-center text-gray-700 border-0 outline-none form-control text-md focus:outline-none"
                          autoFocus={true}
                        />
                        {/*<input*/}
                        {/*  value={zip}*/}
                        {/*  onChange={(e) => setZip(e.target.value)}*/}
                        {/*  type="hidden"*/}
                        {/*  name="PLZ"*/}
                        {/*  placeholder="PLZ"*/}
                        {/*></input>*/}

                        <div
                          className={classNames(
                            "pl-0 sm:pl-20 pb-5 sm:gap-3 sm:grid-flow-row-dense",
                            " sm:grid sm:grid-cols-2"
                          )}
                        >
                          <button
                            type="submit"
                            className="w-full py-2 mt-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-olive hover:bg-amber-500 focus:border-amber-700 active:bg-amber-700 focus:outline-none sm:col-start-2"
                          >
                            Anmelden
                          </button>

                          <button
                            type="button"
                            className="w-full py-2 mt-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-standardGray hover:bg-gray-500 active:bg-gray-700 sm:mt-2 focus:outline-none sm:col-start-0"
                            onClick={() => {
                              setCheckResult(null);
                              onClose()
                              if(redirect) {
                                navigate(redirect);
                              }
                            }}
                            ref={cancelButtonRef.current}
                          >
                            Später
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                  {checkResult === null && (
                    <>
                      <form
                        onKeyPress={(e: React.KeyboardEvent) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            onCheck();
                          }
                        }}
                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onCheck();
                        }}
                      >
                        <input
                          disabled={checking}
                          value={zip}
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLInputElement>
                          ) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder={"Ihre Postleitzahl"}
                          className={classNames(
                            "p-2 text-gray-700 text-center text-md w-full border-0 outline-none focus:outline-none text-sm",
                            checking ? "cursor-not-allowed disabled" : ""
                          )}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setZip(e.target.value)
                          }
                        />

                        <div
                          className={classNames(
                            "pl-0 sm:pl-20 pb-5 mt-1 sm:gap-3 sm:grid-flow-row-dense",
                            " sm:grid sm:grid-cols-2"
                          )}
                        >
                          <button
                            type="submit"
                            className={classNames(
                              "hover:bg-amber-500 focus:border-amber-700 py-2 active:bg-amber-700 w-full mt-2 uppercase text-center focus:outline-none sm:col-start-2 text-sm border-transparent transition ease-in-out duration-150",
                              checking
                                ? " text-white bg-black "
                                : " text-white bg-olive"
                            )}
                          >
                            {checking ? "Bitte warten..." : "Überprüfen"}
                          </button>
                          {!checking && (
                            <button
                              type="button"
                              className="w-full py-2 mt-3 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-standardGray hover:bg-gray-500 active:bg-gray-700 sm:mt-2 focus:outline-none sm:col-start-0"
                              onClick={() => {
                                setCheckResult(null);
                                onClose();
                                if(redirect) {
                                  navigate(redirect);
                                }
                              }}
                              ref={cancelButtonRef.current}
                            >
                              Später
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                  )}

                  {checkResult === "affirmative" && (
                    <div
                      className={classNames(
                        "pl-0 sm:pl-20 pb-5 mt-1 sm:gap-3 sm:grid-flow-row-dense",
                        " sm:grid sm:grid-cols-2"
                      )}
                    >
                      <button
                        type="button"
                        className="w-full py-2 mt-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-standardGray hover:bg-gray-500 active:bg-gray-700 sm:mt-2 focus:outline-none sm:col-start-0"
                        onClick={() => {
                          setCheckResult(null);
                          onClose();
                        }}
                        ref={cancelButtonRef.current}
                      >
                        Züruck
                      </button>
                      <button
                        type="button"
                        className="w-full py-2 mt-2 text-sm text-center text-white uppercase transition duration-150 ease-in-out border-transparent bg-greenDeYork hover:bg-amber-500 active:bg-amber-700 sm:mt-2 focus:outline-none sm:col-start-2"
                        onClick={() => {
                          setCheckResult(null);
                          onClose();
                          if(selectedSlug) {
                            navigate(selectedSlug);
                          }
                        }}
                        ref={cancelButtonRef.current}
                      >
                        Weiter
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
