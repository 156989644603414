import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useCart } from "../context/cart.context";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { IProduct, TCartItem } from "../types/types";

interface CartIndicatorProps {
  isAdditionalProduct: (product: unknown) => boolean;
  findBundledProducts: (product: unknown, products: IProduct[]) => TCartItem[];
}

const CartIndicator: React.FC<CartIndicatorProps> = ({ findBundledProducts, isAdditionalProduct }) => {
  const { cart } = useCart();

  let cartItems = 0;
  cart.products.forEach((item) => {
    if (item.extraData.length >= 4) {
      return cartItems++;
    }
  });
  const bundledProductsLength = cart.products
    .filter(
      (product) =>
        product.product.node.__typename === "BundleProduct"
    )?.length;

  return (
    <Popover className="flow-root text-sm lg:relative">
      <Popover.Button
        ref={cart.cartRef}
        className="flex items-center p-2 -m-2 space-x-1 group sm:space-x-3"
      >
        <ShoppingCartIcon
          className="flex-shrink-0 w-[16px] sm:w-[1.3rem] h-[1.3rem] text-black"
          aria-hidden="true"
        />
        <div className="flex items-center justify-center w-[1.1rem] h-[1.1rem] bg-gray-300 rounded-full group-hover:bg-black group-hover:text-white sm:w-6 sm:h-6 text-xs sm:text-lg">
          {bundledProductsLength}
        </div>
        <span className="sr-only">items in cart, view bag</span>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel className="absolute top-16 inset-x-0 mt-px pb-6 bg-white shadow-lg sm:px-2 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-80  lg:ring-1 lg:ring-black lg:ring-opacity-5">
          <h2 className="sr-only">Shopping Cart</h2>

          <form
            action="/checkout"
            method="get"
            className="max-w-2xl px-4 mx-auto"
          >
            <ul className="divide-y divide-gray-200">
              {cart.products
                .filter(
                  (product) =>
                    product.product.node.__typename === "BundleProduct"
                )
                .map((product, productIdx) => {
                  return (
                    <li key={productIdx} className="flex items-center py-3">
                      <img
                        src={product?.product?.node?.image?.sourceUrl}
                        alt={product.imageAlt}
                        className="flex-none object-contain object-center w-8 h-8 border border-gray-200 "
                      />
                      <div className="flex-auto ml-4">
                        <h3 className="font-medium text-gray-900">
                          <a href={product.href}>
                            {product.extraData.find(
                              (item) => item.key === "isCoupon"
                            )
                              ? ""
                              : ""}{" "}
                            {product.product.node.name}
                          </a>
                          <ul className="text-xs font-light">
                            {findBundledProducts(product, cart.products).map(
                              (subProduct, subProductIdx: number) => (
                                <li key={subProductIdx}>
                                  {subProduct.quantity > 1 &&
                                    subProduct.quantity}{" "}
                                  {subProduct.quantity > 1 && "x"}{" "}
                                  {subProduct.product.node.name}
                                </li>
                              )
                            )}
                          </ul>
                        </h3>
                        <p className="text-gray-500">{product.color}</p>
                      </div>
                    </li>
                  );
                })}
            </ul>

            {cart.products.length > 0 &&
              typeof window !== "undefined" &&
              (window as any)?.localStorage.getItem("brunchbutler_zipCode") && (
                <button
                  type="submit"
                  className="w-full px-4 py-2 mt-3 text-sm font-medium text-white uppercase bg-black border border-transparent shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
                >
                  Zur Kasse
                </button>
              )}

            <p className="mt-6 text-center">
              <a
                href="/cart"
                className="text-sm font-medium text-gray-600 hover:text-gray-500"
              >
                Warenkorb ansehen
              </a>
            </p>
          </form>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default CartIndicator;
