import { Dialog, Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { createRef } from "react";
import Modal from "./modal";
import RedeemModal from "./redeem";
import VoucherModal from "./voucherModal";
import { classNames } from "../utils";
import { scroller } from "react-scroll";
import CartIndicator from "./CartIndicator";

import LogoWide from "../images/logo-wide.svg";
import useCart from "../context/cart.context";
import useIsomorphicLayoutEffect from "../hooks/useIsomorpicLayoutEffect";
import { gql, useQuery } from "@apollo/client";

const isAdditionalProduct: (product: any) => boolean = (product) => {
  const {
    product: {
      node: { upsell },
    },
  } = product;
  return upsell.nodes.length === 0;
};

const getBundleKey: (product: any) => string | undefined = (product) => {
  const { extraData } = product as {
    extraData: { key: string; value: string }[];
  };

  return extraData?.find((metaItem) => metaItem.key === "parent")?.value;
};

const findBundledProducts: (product: any, products: any[]) => any[] = (
  product,
  products
) => {
  if (isAdditionalProduct(product)) {
    return [];
  }

  const bundleKey = getBundleKey(product);

  return products
    .filter((productItem) => isAdditionalProduct(productItem))
    .filter((productItem) => getBundleKey(productItem) === bundleKey)
    .filter((productItem) => productItem.quantity > 0);
};

const isBrowser = typeof window !== "undefined";

const navigation = {
  pages: [
    {
      name: "Bestellen",
      href: "/brunch-bestellen",
      checkDelivery: true,
      redeem: false,
      scrollTarget: "brunch-delivery",
    },
    {
      name: "Schenken",
      href: "/brunch-schenken",
      checkDelivery: true,
      redeem: false,
      scrollTarget: "brunch-vouchers",
    },
    {
      name: "Einlösen",
      href: "/gutschein-einloesen",
      checkDelivery: true,
      redeem: true,
      scrollTarget: "brunch-vouchers",
    },
  ],
};

const Navigation: React.FunctionComponent<
  React.PropsWithChildren<{
    noHero: boolean;
    hideOnStart?: boolean;
    heroSource?: string;
  }>
> = ({ hideOnStart = false }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { data } = useQuery(gql`
    query news {
      menu(id: "hauptmenü", idType: SLUG) {
        menuItems {
          nodes {
            label
            url
            menuCustom {
              checkdelivery
              redeem
              scrolltarget
            }
          }
        }
      }
    }
  `);
  const [navigationState, setNavigationState] = useState<any>(null);

  const [deliveryAreaCheckIsOpen, setDeliveryAreaCheckIsOpen] =
    useState<boolean>(false);
  const [redeemIsOpen, setRedeemIsOpen] = useState<boolean>(false);
  const { zipCode, setZipCode } = useCart();
  // const [zip, setZip] = useState<string>("");
  useIsomorphicLayoutEffect(() => {
    setZipCode(zipCode);
  }, [zipCode, deliveryAreaCheckIsOpen]);

  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [checkVoucherCodeIsOpen, setCheckVoucherCodeIsOpen] =
    useState<boolean>(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  const getOpacity = () => {
    if (isBrowser) {
      const screenHeight = window.document.documentElement.clientHeight / 3;

      const ratio = scrollPosition / screenHeight;

      if (window.document.documentElement.clientWidth <= 640) {
        return 1;
      }

      if (ratio > 1) {
        return 1;
      } else {
        return Math.round(ratio * 10) / 10;
      }
    } else {
      return 1;
    }
  };
  useEffect(() => {
    if (data) {
      const nav = data.menu.menuItems.nodes.map((item: any) => {
        return {
          name: item.label,
          href: item.url,
          checkDelivery: item.menuCustom.checkdelivery,
          redeem: !!item?.menuCustom?.redeem,
          scrollTarget: item.menuCustom.scrolltarget,
        };
      });
      setNavigationState(nav);
    }
  }, [data]);

  const ref = createRef<HTMLButtonElement>();
  useEffect(() => {
    console.log(navigationState);
  }, [navigationState]);

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              style={{ marginTop: "-0.25pt" }}
              className="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white lg:shadow-xl"
            >
              <div className="flex px-4 pt-5 pb-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 -m-2 text-black "
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              <div className="px-4 pt-8 mt-3 space-y-8 border-t-2 border-black pb-7">
                {navigationState?.map((page, index) => {
                  return (
                    <div key={page.name + index} className="flow-root">
                      <a
                        href={page.href}
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement>
                        ) => {
                          if (page.scrollTarget && hideOnStart) {
                            event.preventDefault();
                            event.stopPropagation();

                            scroller.scrollTo(page.scrollTarget, {
                              duration: 800,
                              offset: -50,
                              delay: 0,
                              smooth: "easeInOutQuart",
                            });
                          }
                          if (page.redeem) {
                            if (page.redeem) {
                              event.preventDefault();
                              event.stopPropagation();
                              setRedeemIsOpen(true);
                              setOpen(false);
                            }
                          } else if (page.checkDelivery) {
                            if (
                              page.checkDelivery &&
                              isBrowser &&
                              !(window as any)?.localStorage.getItem(
                                "brunchbutler_zipCode"
                              )
                            ) {
                              event.preventDefault();
                              event.stopPropagation();
                              setDeliveryAreaCheckIsOpen(true);
                              setOpen(false);
                            }
                          }
                        }}
                        className="block px-2 -m-2 font-normal text-gray-900 uppercase"
                      >
                        {page.name}
                      </a>
                    </div>
                  );
                })}
              </div>

              {deliveryAreaCheckIsOpen && (
                <Modal
                  open={false}
                  onClose={(): void => {
                    throw new Error("Function not implemented.");
                  }}
                  pCancelButtonRef={createRef()}
                />
              )}

              <div className="px-4 py-6 space-y-6 border-t border-gray-200">
                <div className="flow-root">
                  <a
                    href={"/cart"}
                    className="block px-2 -m-2 font-medium text-gray-900 uppercase"
                  >
                    Warenkorb
                  </a>
                </div>
                <div className="flow-root">
                  <a
                    href={"/tbd"}
                    className="block px-2 -m-2 font-medium text-gray-900 uppercase"
                  >
                    Kasse
                  </a>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <Modal
        open={deliveryAreaCheckIsOpen}
        onClose={() => setDeliveryAreaCheckIsOpen(false)}
        pCancelButtonRef={ref}
      />

      <RedeemModal
        open={redeemIsOpen}
        onClose={() => setRedeemIsOpen(false)}
        pCancelButtonRef={ref}
      />
      <VoucherModal
        open={checkVoucherCodeIsOpen}
        onClose={() => setCheckVoucherCodeIsOpen(false)}
        mood={"neutral"}
        pCancelButtonRef={React.createRef()}
      />

      <header
        className={classNames(
          "z-101 top-0 left-0 right-0 bg-white w-screen",
          scrollPosition > 0 ||
            // (isBrowser && window.document.documentElement.clientWidth <= 640) ||
            hideOnStart === false
            ? `fixed`
            : `hidden`
        )}
      >
        <nav
          aria-label="Top"
          style={{ opacity: hideOnStart ? getOpacity() : 1 }}
        >
          {/* Top navigation */}

          {/* Secondary navigation */}
          <div className="border-b-2 border-black lg:border-transparent lg:shadow-lg">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="">
                <div className="relative flex items-center justify-between h-16">
                  {/* Logo (lg+) */}
                  <div className="hidden lg:flex lg:items-center">
                    <a href="/">
                      <span className="sr-only">Workflow</span>
                      <div className="w-auto h-5">
                        <LogoWide height="100%" width="auto" />
                      </div>
                    </a>
                  </div>

                  <div className="absolute hidden h-full transform -translate-x-1/2 left-1/2 lg:flex">
                    {/* Mega menus */}
                    <Popover.Group>
                      <div className="flex justify-center h-full space-x-16">
                        {navigationState?.map((page, index) => (
                          <a
                            key={page.name + index}
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>
                            ) => {
                              if (page.scrollTarget && hideOnStart) {
                                event.preventDefault();
                                event.stopPropagation();

                                scroller.scrollTo(page.scrollTarget, {
                                  duration: 800,
                                  offset: -50,
                                  delay: 0,
                                  smooth: "easeInOutQuart",
                                });
                              }
                              if (page.redeem) {
                                if (page.redeem) {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setRedeemIsOpen(true);
                                }
                              } else if (page.checkDelivery) {
                                if (
                                  page.checkDelivery &&
                                  isBrowser &&
                                  !(window as any)?.localStorage.getItem(
                                    "brunchbutler_zipCode"
                                  )
                                ) {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setDeliveryAreaCheckIsOpen(true);
                                }
                              }
                            }}
                            href={page.href}
                            className="flex items-center text-lg font-light text-gray-700 uppercase hover:text-standardGray"
                          >
                            {page.name}
                          </a>
                        ))}
                      </div>
                    </Popover.Group>
                  </div>

                  {/* Mobile menu and search (lg-) */}
                  <div className="items-center lg:hidden">
                    <button
                      type="button"
                      className="p-2 -ml-2 text-black bg-white lg:text-gray-400"
                      onClick={() => setOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Logo (lg-) */}
                  <a
                    href="/"
                    className="flex-grow mx-auto sm:px-6 lg:hidden bg-green"
                  >
                    <span className="sr-only">Brunchbutler</span>
                    <LogoWide className="h-[11px]" />
                  </a>

                  <div className="flex items-center justify-end ml-2 sm:ml-0">
                    <button
                      onClick={() => setDeliveryAreaCheckIsOpen(true)}
                      className="flex items-center space-x-1 cursor-pointer sm:space-x-3 group"
                    >
                      <p className="text-sm sm:text-lg">PLZ</p>
                      <div className="px-2 rounded-full bg-gray-300 group-hover:text-white min-w-[30px] min-h-[15px] sm:min-w-[45px] sm:min-h-[20px]  group-hover:bg-black text-xs sm:text-lg">
                        {zipCode ? zipCode : ""}
                      </div>
                    </button>

                    <div className="flex items-center lg:ml-8">
                      <CartIndicator
                        isAdditionalProduct={isAdditionalProduct}
                        findBundledProducts={findBundledProducts}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navigation;
